<template>
<!-- 迪荡湖街道  运动时长排名 -->
    <div class="baixing darkBack">
        <div class="baixingnav">
            <div>运动时长排名(时)</div>
        </div>
        <div id="jianoption1" style="width:5.50rem; height:5rem;margin-top:0.2rem;"></div>
    </div>
</template>

<script>
import bus from '/src/assets/js/bus.js'

export default {
    data () {
        return {
            sport:{
                data: [],
                number:[],
                max: 16,
            },
        }
    },
    methods: {
        selectedhandle(e){          // 点击切换样式
            // $('.selecte').class('background', "#000000")
            $('.selecte').css({'background-color':'#000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // 切换柱状图表
            var switchover = e.target.innerText
        },
        fitness(id, number){
            let that = this;
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            // var data = number.number

            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    splitNumber: 6,
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: '#2D94D040',
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '4%',  
                    // right: '16%', 
                    top:'2%', 
                    bottom: '1%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#2D94D0',
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        // margin: 176,//刻度标签与轴线之间的距离
                        interval:0,//横轴信息全部显示 
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E2",
                            fontSize: '0.13rem',
                            // align: 'left'
                        },                           
                    },
                },
                series: [{
                    // realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barMaxWidth:20,
                    barWidth :'40%',              // 柱子的宽度
                    data: number.number,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: "#2D94D0" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#3AE6CE" // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: '#3AE5CE',    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };
            canye.setOption(option);

            //图表自动缩放
            window.addEventListener("resize",function(){
              canye.resize();
              });
        },
        // 获取时长排名
        getTimeRank(){
            let that = this;
            // let url = window.appHost+"/api/v1/screen/Street/timeNum?street="+window.clickstreet;
            let url = window.appHost+"/api/v1/screen/Street/timeNum?area="+window.communityname;
            let result = window.ajaxRequest(url)
            // console.log(result)
            that.sport = {
                data: [],
                number:[],
                max: result.data.length,
            }
            result.data.forEach((item,index)=>{
                that.sport.data.push(item.street);
                that.sport.number.push(item.timenum);
            })
            that.fitness("jianoption1", that.sport)

        }
    },
    mounted () {
        // this.fitness("jianoption1", this.sport)
        this.getTimeRank();
        bus.$on('now_street',res=>{
            // console.log(res)
            this.getTimeRank();
        })
    },
    beforeDestroy(){
        bus.$off('now_street')
    }
}
</script>

<style scoped>
.baixing{
    width:5.94rem;
    height:5.9rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
</style>